#App {
  text-align: center;
}

.anchor {
  display: inline-block;
  font-size: 16px;
}
a:-webkit-any-link {
  text-decoration: none;
}

header {
  position: fixed;
  height: 100px;
  top: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.1);
}

.header-wrapper {
  height: 100px;
  line-height: 100px;
  align-items: center;
}

.header-left {
  display: inline-block;
}
.logo {
  width: 88px;
  height: 62px;
}

.anchor-item {
  color: #252120;
  margin-right: 80px;
}

.header-wrapper .anchor-item:last-child {
  margin-right: 0;
}

#home {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  background-color: #B03F28;
  height: 600px;
}

.home-left {
  color: #FFFFFF;
  text-align: center;
}

.img-div {
  text-align: center;
  margin-top: 50px;
}

.arrow {
  width: 16px;
}

.logo2 {
  width: 130px;
}

.light, .bold {
  font-size: 50px;
  line-height: 1;
}

.light {
  margin-top: 70px;
  font-weight: 300;
}

.bold {
  font-weight: bold;
  margin-top: 8px;
}

.stxt {
  margin-top: 65px;
}

.stxt, .stxt2 {
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
}

.phone1 {
  margin-top: 45px;
  width: 371px;
  height: 744px;
}

.fixed-width {
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.service-title {
  margin-top: 180px;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
}

.black {
  color: #252120;
}

.red {
  color: #B03F28;
}

.outest-flex {
  display: flex;
  justify-content: center;
}

.service-content {
  margin-top: 50px;
}

.icon {
  width: 120px;
}

.flex-col {
  width: 310px;
}

.col-title {
  color: #252120;
  font-size: 26px;
  margin-top: 35px;
  line-height: 1;
}

.col-desc {
  margin-top: 8px;
  font-size: 16px;
  line-height: 36px;
  color: #817F80;
}

.contact-left {
  text-align: left;
  width: 513px;
}

.china {
  color: #252120;
  font-size: 30px;
  font-weight: bold;
}

.english {
  margin-left: 20px;
  color: #B03F28;
  font-size: 18px;
}

.coffee {
  width: 640px;
}

#about {
  margin-top: 100px;
}

.contact-title {
  margin: 100px 0 40px;
}

.contact-content {
  font-size: 16px;
  color: #817F80;
  line-height: 36px;
}

.why-right {
  width: 640px;
  text-align: left;
  margin-top: 120px;
}

#why {
  margin-top: 72px;
  background-color: #FFF9F2;
}

.phone2 {
  margin-top: 44px;
  width: 369px;
}

.why-title {
  margin-bottom: 30px;
}

.why-content {
  width: 513px;
  font-size: 16px;
  color: #817F80;
  line-height: 36px;
}

.why2-title {
  margin-top: 150px;
}

#why2 {
  background-color: #FFF3E3;
}
.phone3 {
  margin-top: 43px;
  width: 372px;
}

#why3 {
  background-color: #B03F28;
  height: 460px;
}

.app-logo {
  margin: 70px 47px 0 0;
  width: 421px;
}

.why3-right {
  text-align: left;
  margin: 84px 80px 0 0;
}

.why3-txt {
  font-size: 36px;
  color: #FFFFFF;
  font-weight: bold;
}

.btn {
  width: 180px;
  height: 60px;
  line-height: 60px;
  margin-top: 150px;
  text-align: center;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  font-size: 20px;
  background-color: #B03F28;
}

#contact {
  background-color: #252120;
  height: 200px;
}

.foot-left {
  margin-top: 90px;
  color: #B0AFAD;
  font-size: 16px;
}

.foot-right-title {
  color: #FFFFFF;
  font-size: 20px;
  margin: 25px 0 30px;
}

.foot-right {
  text-align: left;
  margin-right: 106px;
  margin-top: 30px;
}

.value {
  color: #FFFFFF;
  font-size: 18px;
}

.location-icon {
  width: 18px;
  margin-right: 21px;
}

.address-icon {
  width: 22px;
  margin-right: 19px;
}

.info-row {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}